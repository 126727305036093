<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="rows layout-column">
        <div class="layout-row row-1 layout-align-center-center">
          <div
            class="layout-column col-1 col-padding layout-align-center-end flex"
          >
            <section-title
              :partNo="part.id"
              :partTitle="part.title"
              data-depth="2.5"
              class="text-right parallax-item parallax-relative"
              :show="showTitle"
            ></section-title>
          </div>
          <div
            class="
              layout-column
              col-padding col-2
              layout-align-center-start
              flex
            "
          >
            <lead
              :show="showLead"
              class="parallax-item parallax-relative"
              data-depth="2"
              >{{ data.meta.description }}</lead
            >
          </div>
        </div>
        <div class="layout-row row-2 layout-align-center-start">
          <timeline
            :decal="data.images.decal"
            :ink="data.images.inkWide"
            :show="showDiagram"
            :data="data.timelines"
          ></timeline>
        </div>
      </div>
    </div>
    <div class="container fluid absolute">
      <decal
        class="absolute"
        :show="showDecal"
        :image="data.images.panorama"
        :parallaxDepth="1"
        :customTimeline="timelines.timelineDecal"
      ></decal>
    </div>

    <div class="container absolute ink-container flex z-index-0">
      <ink
        :customTimeline="timelines.timelineInk"
        :show="showInk"
        :image="data.images.ink"
      ></ink>
    </div>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import Timeline from "@/components/ui/Timeline.vue";
import Decal from "@/components/ui/Decal.vue";
import Ink from "@/components/ui/Ink.vue";

export default {
  name: "Chapter-3-2",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Lead,
    Timeline,
    Decal,
    Ink,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 200],
        ["showTitle", 600],
        ["showDiagram", 200],
        ["showDecal", 100],
        ["showInk", 100],
        ["showLead", 1000],
      ],
      timelines: {
        timelineInk: {
          duration: 0.7,
          animations: {
            scale: 1,
            x: "+=20%",
            ease: Power2.easeOut,
          },
        },
        timelineDecal: {
          duration: 1,
          animations: {
            ease: Power1.easeOut,
            autoAlpha: 0,
            y: "+=50",
          },
        },
      },
    };
  },
  computed: {},
};
</script>

<style scoped>
.row-1 {
  padding-bottom: 0.5%;
}
.col-1 {
  max-width: 45.5%;
}
.col-2 {
  padding-left: 0;
}
/deep/ .lead {
  max-width: 640px;
}
.section-title {
  max-width: 41em !important;
}
.section-decal {
  width: 102%;
  margin: 0;
  opacity: 0.85;
  min-width: 800px;
  bottom: -6.5vh;
}
.ink-container {
  align-items: center;
  justify-content: center;
  height: 100%;
}
.section-ink {
  margin-left: -10%;
  margin-top: -30%;
  opacity: 0.4;
}
/deep/ .section-ink img {
  max-width: 730px;
  width: 100%;
  min-width: 340px;
  display: block;
}
/deep/ .section-decal img {
  width: 100%;
}
/deep/ .bird {
  margin: -0.6em 0 0 0.7em;
}
/deep/ .image-box-caption .caption-main {
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.6);
}
.ink-container {
  height: 100vh;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (min-width: 1024px) {
  /deep/ .slide-text {
    max-width: 18em;
  }
}
@media screen and (max-width: 1600px) {
  .section-decal {
    bottom: -6vh;
  }
  .section-ink {
    margin-left: -10%;
    margin-top: -38%;
    max-width: 95vw;
  }
}
@media screen and (max-width: 1440px) {
  /deep/ .lead {
    max-width: 37em;
  }
  /deep/ .bird {
    margin: -1em 0 0 0.7em;
  }
  .section-decal {
    bottom: -5.5vh;
  }
}
@media screen and (max-width: 1200px) {
  .row-1 {
    flex-direction: column;
  }
  .row-1 > * {
    max-width: 100%;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: var(--col-padding);
  }
  .section-title {
    max-width: 100% !important;
  }
  .part-lead {
    margin-bottom: 0.7rem;
  }
  .section-ink {
    margin-left: 10%;
    margin-top: -37%;
    width: 600px;
  }
  .section-decal {
    bottom: -3.5vh;
  }
}
@media screen and (max-width: 1024px) {
  .section-decal {
    bottom: -1.5vh;
  }
  .section-ink {
    margin-top: -44%;
  }
}

@media screen and (max-width: 991px) {
  .section-title {
    max-width: 11em !important;
  }
  .section-ink {
    margin-left: 12%;
    margin-top: -55%;
    width: 560px;
  }
  .landscape .section-ink {
    margin-top: -14%;
  }
  .section-decal {
    bottom: -1.5vh;
  }
}
@media screen and (max-width: 767px) {
  /deep/ .bird {
    margin: 0.3em 0 0 0.7em;
  }
  .section-decal {
    left: auto !important;
    right: 0;
  }
  .section-ink {
    margin-left: 12%;
    margin-top: -54%;
  }
  .landscape .section-ink {
    margin-top: -8%;
  }
}

@media screen and (max-width: 639px) {
  /deep/ .lead {
    max-width: 29.8em;
  }
  .section-ink {
    margin-left: 12%;
    margin-top: -78%;
  }
}
@media screen and (max-width: 479px) {
  .section-title {
    max-width: 4.8em !important;
  }
  .section-ink {
    margin-left: 6%;
    margin-top: -90%;
  }
}
@media screen and (max-width: 540px) {
  /deep/ .bird {
    margin: -0.7em 0 0 0.4em;
  }
  .section-decal {
    min-width: 700px;
  }
  .part-lead {
    margin-right: var(--col-padding-adjust);
  }
}
@media screen and (max-width: 420px) {
  .section-ink {
    margin-top: -110%;
  }
}
@media screen and (max-width: 390px) {
  /deep/ .bird {
    margin: -0.3em 0 0 0.4em;
  }
}
</style>
