<template>
  <div class="part-component section-ink" ref="image">
    <img
      class="parallax-relative parallax-item"
      :src="image"
      :data-depth="parallaxDepth"
    />
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "Ink",
  mixins: [partComponent],
  props: {
    image: String,
    parallaxDepth: {
      type: Number,
      default: 1.5,
    },
    customTimeline: {
      type: Object,
      default: function () {
        return {
          duration: 5,
          animations: {
            ease: Power1.easeOut,
            scale: 1.2,
            x: "-=15%",
            y: "-=0.3%",
          },
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const { image } = this.$refs;

      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        image,
        1,
        {
          ease: Power1.easeIn,
          autoAlpha: 0,
        },
        0
      ).from(
        image,
        this.customTimeline.duration,
        this.customTimeline.animations,
        0
      );

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.section-ink {
  z-index: 1;
  -ms-user-select: none;
  user-select: none;
  left: -16%;
  top: 36%;
  opacity: 0.8;
}
.section-foreground .section-ink {
  z-index: -1;
}
</style>
